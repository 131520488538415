import { Injectable, computed } from '@angular/core';
import { fromEvent, map, startWith } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';

enum Size {
  xxs = 'xxs',
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
  xxl = 'xxl',
}

const DEFAULT_PAGE_WIDTH: Record<Size, number> = {
  xxs: 320,
  xs: 480,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1536,
};

@Injectable({
  providedIn: 'root',
})
export class PageUtils {
  screenWidth$ = fromEvent(window, 'resize').pipe(
    map(() => window.innerWidth), // Pega a largura atual da janela
    startWith(window.innerWidth), // Emite a largura inicial imediatamente
  );
  screenWidth = toSignal(this.screenWidth$, {
    initialValue: window.innerWidth,
  });

  breakpoint = {
    xxs: computed(() => this.screenWidth() >= DEFAULT_PAGE_WIDTH[Size.xxs]),
    xs: computed(() => this.screenWidth() >= DEFAULT_PAGE_WIDTH[Size.xs]),
    sm: computed(() => this.screenWidth() >= DEFAULT_PAGE_WIDTH[Size.sm]),
    md: computed(() => this.screenWidth() >= DEFAULT_PAGE_WIDTH[Size.md]),
    lg: computed(() => this.screenWidth() >= DEFAULT_PAGE_WIDTH[Size.lg]),
    xl: computed(() => this.screenWidth() >= DEFAULT_PAGE_WIDTH[Size.xl]),
    xxl: computed(() => this.screenWidth() >= DEFAULT_PAGE_WIDTH[Size['2xl']]),
  };
}
